<template>
  <div>
    <b-card>
      <div class="filter-wrap flex-between">
        <SearchFilter
          :search.sync="listQuery.SearchStr"
          @handleFilter="handleFilter"
        />
        <el-button
          v-if="savePerm"
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="saveDialogShow(1)"
        >
          新增
        </el-button>
      </div>
      <FilterSelectList
        :list-query.sync="listQuery"
        :select-list.sync="selectList"
        @handleFilter="handleFilter"
      />
      <el-table
        v-loading="loading"
        border
        fit
        highlight-current-row
        :data="tableData"
        stripe
        style="width: 100%"
      >
        <el-table-column
          prop="cookieName"
          :show-overflow-tooltip="true"
          label="名称"
        />
        <el-table-column
          prop="purposeName"
          label="类型"
        >
          <template
            slot="header"
            slot-scope="scope"
          >
            <TableHeaderFilterDropdown
              :key-str="'purposeId'"
              :select-list.sync="selectList"
              :label="'类型'"
              :value.sync="listQuery.purposeId"
              :dropdown-options="cookieTypeList"
              @handleFilter="handleFilter"
            />
          </template>
          <template slot-scope="{row}">
            {{ row.purposeName }}
          </template>
        </el-table-column>
        <el-table-column
          prop="holder"
          :show-overflow-tooltip="true"
          label="所有者"
        />
        <el-table-column
          prop="host"
          :show-overflow-tooltip="true"
          label="host"
        />
        <el-table-column
          prop="effectiveTime"
          :show-overflow-tooltip="true"
          label="有效期"
        />
        <el-table-column
          prop="PrivacyPolicyUrl"
          :show-overflow-tooltip="true"
          label="隐私政策链接"
        />
        <el-table-column
          prop="remark"
          :show-overflow-tooltip="true"
          label="描述"
        />
        <el-table-column
          width="100"
          prop="createTime"
          label="创建时间"
        >
          <template slot-scope="scope">
            {{ formatDateStr(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="100px"
        >
          <template slot-scope="{row}">
            <el-link
              v-if="updatePerm"
              :underline="false"
              class="mr10"
              type="primary"
              @click="saveDialogShow(2,row)"
            >
              <el-tooltip
                content="修改"
                placement="top"
                effect="light"
              >
                <i class="el-icon-edit" />
              </el-tooltip>
            </el-link>
            <el-link
              v-if="delPerm"
              :underline="false"
              type="primary"
              @click="del(row.id)"
            >
              <el-tooltip
                content="删除"
                placement="top"
                effect="light"
              >
                <i class="el-icon-delete" />
              </el-tooltip>
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="total>0"
        :total="total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.pageSize"
        @pagination="getList"
      />
    </b-card>
    <CookiesEdit
      ref="cookiesEdit"
      @handleFilter="handleFilter"
    />
  </div>
</template>

<script>
import pagination from '@core/components/pagination/Pagination.vue'
import CookiesEdit from '@/views/cookies/CookiesEdit.vue'
import { ListCookieInfo, DelCookie, ListCookieType } from '@/api/cpm/cpm'
import {
  error, findBtnPerm, success, getBtnPerms, verifyBtnPerm,
} from '@core/utils/utils'
import FilterSelectList from '@core/components/filter-select-list/FilterSelectList.vue'
import TableHeaderFilterDropdown from '@core/components/table-header-fliter-dropdown/TableHeaderFilterDropdown.vue'

export default {
  components: {
    pagination,
    CookiesEdit,
    FilterSelectList,
    TableHeaderFilterDropdown,
  },
  data() {
    return {
      savePerm: findBtnPerm('cookies-list-2'),
      updatePerm: findBtnPerm('cookies-list-3'),
      delPerm: findBtnPerm('cookies-list-4'),
      loading: false,
      total: 0,
      cookieTypeList: [],
      selectList: [],
      listQuery: {
        page: 1,
        pageSize: 10,
        SearchStr: '',
        purposeId: 0,
      },
      tableData: [],
    }
  },
  created() {
    getBtnPerms(btnPerms => {
      this.savePerm = verifyBtnPerm(btnPerms, 'cookies-list-2')
      this.updatePerm = verifyBtnPerm(btnPerms, 'cookies-list-3')
      this.delPerm = verifyBtnPerm(btnPerms, 'cookies-list-4')
    })
    this.getList()
    this.listCookieType()
  },
  methods: {
    del(id) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      })
        .then(() => {
          DelCookie({ id })
            .then(res => {
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.handleFilter()
              } else {
                error(resData.msg)
              }
            })
        })
    },
    listCookieType() {
      ListCookieType()
        .then(res => {
          const resData = res.data
          if (resData.code === 0) {
            resData.data.forEach(c => {
              this.cookieTypeList.push({
                label: c.name,
                value: c.id,
              })
            })
          }
        })
    },
    saveDialogShow(type, row) {
      this.$refs.cookiesEdit.dialogVisible = true
      if (type === 2) {
        this.$refs.cookiesEdit.form.id = row.id
        this.$refs.cookiesEdit.form.holder = row.holder
        this.$refs.cookiesEdit.form.PrivacyPolicyUrl = row.PrivacyPolicyUrl
        this.$refs.cookiesEdit.form.cookieName = row.cookieName
        this.$refs.cookiesEdit.form.effectiveTime = row.effectiveTime
        this.$refs.cookiesEdit.form.host = row.host
        this.$refs.cookiesEdit.form.remark = row.remark
        this.$refs.cookiesEdit.form.purposeId = row.purposeId
      }
    },
    getList() {
      this.loading = true
      this.listQuery.purposeId = Number(this.listQuery.purposeId)
      ListCookieInfo(this.listQuery)
        .then(res => {
          this.loading = false
          this.tableData = res.data.data.list
          this.total = Number(res.data.data.total)
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
  },
}
</script>

<style scoped>

</style>
